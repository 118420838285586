import TextField from '@engined/client/components/forms/fields/TextField.js';
import { FormErrors } from '@engined/client/hooks/useFormResolver.js';
import { Grid } from '@mui/material';
import React from 'react';

export interface FormValues {
  id?: string;
  updatedAt?: string;
  name: string;
  position?: number;
}
interface OwnProps {
  className?: string;
  showPosition?: boolean;
}

type Props = OwnProps;

const PhaseGroupForm: React.FunctionComponent<Props> = ({ className, showPosition }) => {
  return (
    <Grid container spacing={2}>
      {showPosition && (
        <Grid item xs={12} md={6}>
          <TextField
            name="position"
            type="number"
            label="Pozícia"
            required
            inputProps={{ min: '0', step: '1' }}
            fullWidth
          />
        </Grid>
      )}
      <Grid item xs={12} md={showPosition ? 6 : 12}>
        <TextField name="name" type="text" label="Názov" required maxLength={255} fullWidth />
      </Grid>
    </Grid>
  );
};

PhaseGroupForm.displayName = 'PhaseGroupForm';

export default PhaseGroupForm;

export function validate(values: FormValues) {
  const errors: FormErrors<FormValues> = {};

  if (!values.name) {
    errors.name = 'Názov skupiny je povinný';
  }

  if (values.id) {
    if (!values.position && values.position !== 0) {
      errors.position = 'Pozícia je povinná';
    } else if (values.position < 0) {
      errors.position = 'Pozícia je neplatná';
    }
  }

  return errors;
}
